import React from 'react';
import { Link } from 'react-router-dom';
import posts from './blogData.ts';
import './Blog.css';

const Blog: React.FC = () => {
  return (
    <div className="blog-container">
      <h1>Latest Blog Posts:</h1>
      <div className="blog-list">
        {posts.map((post) => (
          <div key={post.id} className="blog-summary">
            {post.coverImage && (
              <img 
                src={post.coverImage} 
                alt={post.title} 
                className="blog-cover-image"
              />
            )}
            <h2>
              <Link to={`/blog/${post.slug}`}>{post.title}</Link>
            </h2>
            <p className="blog-date">{post.date}</p>
            <p>{post.excerpt}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;