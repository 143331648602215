// src/components/Recents.tsx

import React, { useState, useEffect, useRef } from 'react';
import './style/Recents.css';
import { recents, RecentRV } from './data/recents.ts';

function Recents() {
  const [itemsPerView, setItemsPerView] = useState(3);
  const carouselRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null); // the .carousel-wrapper
  const offsetRef = useRef(0);
  const animationFrameRef = useRef<number | null>(null);
  const isPausedRef = useRef(false);
  const SCROLL_SPEED = 0.8; 

  // Duplicate your data (ensure enough copies for smooth scrolling)
  const extendedRecents = [...recents, ...recents, ...recents, ...recents];

  // itemsPerView logic
  useEffect(() => {
    const updateItemsPerView = () => {
      const width = window.innerWidth;
      if (width < 600) setItemsPerView(1);
      else if (width < 900) setItemsPerView(2);
      else setItemsPerView(3);
    };
    updateItemsPerView();
    window.addEventListener('resize', updateItemsPerView);
    return () => window.removeEventListener('resize', updateItemsPerView);
  }, []);

  // Animation tick function using requestAnimationFrame
  const tick = () => {
    if (isPausedRef.current) {
      animationFrameRef.current = requestAnimationFrame(tick);
      return;
    }

    const container = carouselRef.current;
    const wrapper = wrapperRef.current;
    if (!container || !wrapper) return;

    // Move left by 0.5px each frame (~30px/s at 60fps)
    let offset = offsetRef.current - SCROLL_SPEED;
    container.style.transform = `translateX(${offset}px)`;
    offsetRef.current = offset;

    // Get bounding rectangles
    const containerRect = wrapper.getBoundingClientRect();
    const firstItem = container.firstElementChild as HTMLElement | null;

    if (firstItem) {
      const firstItemRect = firstItem.getBoundingClientRect();

      // If the first item's right edge is left of the container's left edge, move it to the end
      if (firstItemRect.right < containerRect.left) {
        // Get computed styles to retrieve the gap
        const styles = window.getComputedStyle(container);
        const gap = parseFloat(styles.columnGap) || 0;

        // Calculate full width (item width + gap)
        const itemFullWidth = firstItem.offsetWidth + gap;

        // Move the first item to the end
        container.appendChild(firstItem);

        // Adjust offset to account for the moved item's width and gap
        offsetRef.current += itemFullWidth;
        // Snap to integer to prevent subpixel rendering issues
        offsetRef.current = Math.round(offsetRef.current);
        container.style.transform = `translateX(${offsetRef.current}px)`;
      }
    }

    // Schedule the next frame
    animationFrameRef.current = requestAnimationFrame(tick);
  };

  // Start the animation loop
  useEffect(() => {
    animationFrameRef.current = requestAnimationFrame(tick);
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerView]);

  // Handle pause on hover
  const handleMouseEnter = () => {
    isPausedRef.current = true;
  };

  const handleMouseLeave = () => {
    isPausedRef.current = false;
  };

  return (
    <div className="recents-container">
      <h2 className="recents-title">Recently Quoted:</h2>
      <div
        className="carousel"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="carousel-wrapper" ref={wrapperRef}>
          <div className="carousel-items" ref={carouselRef}>
            {extendedRecents.map((rv: RecentRV, i) => (
              <div
                className="carousel-item"
                key={i}
                style={{ flex: `0 0 ${100 / itemsPerView}%` }}
              >
                <h3>{rv.title}</h3>
                <img src={rv.image} alt={rv.title} />
                <p>{rv.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recents;