// src/components/Menu.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import './style/Menu.css';

interface MenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
  handleEmailClick: () => void;
}

const Menu: React.FC<MenuProps> = ({ isOpen, toggleMenu, handleEmailClick }) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-button" onClick={toggleMenu} aria-label="Close Menu">
        <img src="/icons/close.png" alt="Close Menu" className="close-icon" />
      </button>
      <nav className="sidebar-links">
        <Link to="/" className="sidebar-link" onClick={toggleMenu}>
          Home
        </Link>
        <Link to="/buy" className="sidebar-link" onClick={toggleMenu}>
          Buy an RV
        </Link>
        <Link to="/sell" className="sidebar-link" onClick={toggleMenu}>
          Sell my RV
        </Link>
        <Link to="/blog" className="sidebar-link" onClick={toggleMenu}>
          Blog
        </Link>
        <Link to="/about" className="sidebar-link" onClick={toggleMenu}>
          About Us
        </Link>
        <button onClick={() => { handleEmailClick(); toggleMenu(); }} className="sidebar-button">
          Contact Us
        </button>
      </nav>
    </div>
  );
};

export default Menu;