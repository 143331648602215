// src/components/FAQ.tsx

import React, { useState } from 'react';
import './style/FAQ.css';

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "Is RVbig actually free?",
    answer: "Yes, RVbig 100% free for individuals, zero fees for buying or selling.",
  },
  {
    question: "Who do I buy from or sell to?",
    answer: `RV dealers from our trusted network, who will compete for your business.
     Skip the haggling, and get straight to the best deal!`,
  },
  {
    question: "How do I choose the best offer?",
    answer: "You can review all the offers received. Compare prices, dealer ratings, location and other terms. Then pick the best deal for you.",
  },
];

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq">
      <h2 className="faq-title">Frequently Asked:</h2>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <div 
            key={index} 
            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
          >
            <div 
              className="faq-question" 
              onClick={() => toggleFAQ(index)}
              aria-expanded={activeIndex === index}
            >
              {item.question}
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;