import postsMeta from './posts.json';

export interface BlogPostMeta {
  id: string;
  title: string;
  date: string;
  excerpt: string;
  slug: string;
  file: string;
}

const posts: BlogPostMeta[] = postsMeta || [];

export default posts;