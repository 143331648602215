// src/components/MainBanner.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style/MainBanner.css';

const MainBanner: React.FC = () => {
  const navigate = useNavigate();

  const handleBuy = () => {
    navigate('/buy');
  };

  const handleSell = () => {
    navigate('/sell');
  };

  return (
    <div className="main-banner">
      <div className="banner-overlay">
        <h1 className="banner-title">
            Buy or sell RVs,<br />
            the <strong>free</strong> & <strong>easy</strong> way.
        </h1>
        <div className="tagline">
          <em>The #1 way for RV enthusiasts to find the best deal.</em>
        </div>
        <div className="banner-buttons">
          <button
            className="action-button"
            onClick={handleBuy}
            aria-label="Buy an RV"
          >
            Buy an RV
          </button>
          <button
            className="action-button"
            onClick={handleSell}
            aria-label="Sell my RV"
          >
            Sell my RV
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;