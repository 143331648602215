// src/components/HowItWorks.tsx

import React from 'react';
import './style/HowItWorks.css';

const HowItWorks: React.FC = () => {
  return (
    <div className="how-it-works">
      <h2 className="how-it-works-title">Three Simple Steps:</h2>
      <div className="steps">
        <div className="step-box">1) Enter RV details</div>
        <div className="step-box">2) Let dealers compete for your business</div>
        <div className="step-box">3) Pick the best offer!</div>
      </div>
    </div>
  );
};

export default HowItWorks;