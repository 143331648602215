// App.tsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Grid from './components/Grid.tsx';
import Header from './components/Header.tsx';
import Footer from './components/Footer.tsx';
import PrivacyPolicy from './components/PrivacyPolicy.tsx';
import TermsAndConditions from './components/TermsAndConditions.tsx';
import ShareCard from './components/ShareCard.tsx';
import Home from './components/Home.tsx';
import About from './components/About.tsx';
import Buy from './components/Buy.tsx';
import Sell from './components/Sell.tsx';
import Success from './components/Success.tsx';
import Blog from './components/blog/Blog.tsx';
import BlogPost from './components/blog/BlogPost.tsx';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/find-new-rv" element={<Grid />} />
            <Route path="/rv/:id" element={<ShareCard />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/about" element={<About />} />
            <Route path="/buy" element={<Buy />} />
            <Route path="/sell" element={<Sell />} />
            <Route path="/success" element={<Success />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:postSlug" element={<BlogPost />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;