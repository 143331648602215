// Footer.tsx
import { Link } from 'react-router-dom';
import './style/Footer.css';

const Footer: React.FC = () => {

  return (
    <footer className="App-footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} RVbig. All rights reserved.</p>
        <Link to="/privacy-policy" className="footer-link">
          Privacy Policy
        </Link>
        <Link to="/terms-and-conditions" className="footer-link">
          Terms and Conditions
        </Link>
      </div>
    </footer>
  );
};

export default Footer;