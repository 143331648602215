import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import posts from './blogData.ts';
import './BlogPost.css';

const BlogPost: React.FC = () => {
  const { postSlug } = useParams<{ postSlug: string }>();
  const postMeta = posts.find((p) => p.slug === postSlug);

  const [content, setContent] = useState<string>('');

  // Always call useEffect. Only trigger loading if postMeta exists.
  useEffect(() => {
    if (postMeta) {
      import(`!!raw-loader!./posts/${postMeta.file}`)
        .then((module) => setContent(module.default))
        .catch((error) => console.error('Error loading markdown:', error));
    }
  }, [postMeta]);

  if (!postMeta) {
    return <Navigate to="/blog" replace />;
  }

  return (
    <div className="blog-post">
      <h1>{postMeta.title}</h1>
      <div className="blog-date">{postMeta.date}</div>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default BlogPost;